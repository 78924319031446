export enum GetServiceActionTypes {
  GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST',
  SET_EMAIL = 'SET_EMAIL',
  VALIDATE_EMAIL = 'VALIDATE_EMAIL',
  INITIAL_FORM = 'INITIAL_FORM',
  EMAIL_SIGNUP_FORM = 'EMAIL_REGISTRATION_FORM',
  PASSWORD_SIGNUP_FORM = 'PASSWORD_REGISTRATION_FORM',
  CODE_SIGNUP_FORM = 'CODE_REGISTRATION_FORM',
  LOADING_SIGNUP_FORM = 'LOADING_SIGNUP_FORM',
  EMAIL_LOGIN_FORM = 'EMAIL_PASSWORD_FORM',
  FORGOT_PASSWORD_FORM = 'FORGOT_PASSWORD_FORM',
  CODE_LOGIN_FORM = 'CODE_LOGIN_FORM',
  NEW_PASSWORD_FORM = 'NEW_PASSWORD_FORM',
  LOADING_LOGIN_FORM = 'LOADING_LOGIN_FORM',
  SIGNIN_CONFIRM = 'SIGNIN_CONFIRM',
  SIGNIN_HEADER = 'SIGNIN_HEADER',
  SET_PASSWORD = 'SET_PASSWORD',
  SIGNUP_FAILED_FORM = 'SIGNUP_FAILED_FORM',
  SIGNUP_SUCCEEDED_FORM = 'SIGNUP_SUCCEEDED_FORM',
  CONFIRM_SIGNUP = 'CONFIRM_SIGNUP',
  SIGNIN = 'SIGNIN',
  SIGNIN_SUCCESS = 'SIGNIN_SUCCESS',
  SIGNIN_FAILURE = 'SIGNIN_FAILURE',
  SIGNUP_FAILED = 'SIGNUP_FAILED',
  SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED',
  OPEN_BLOCK = 'OPEN_BLOCK',
  CLOSE_BLOCK = 'CLOSE_BLOCK',
  OPEN_HOMESCREEN = 'OPEN_HOMESCREEN',
  CLOSE_HOMESCREEN = 'CLOSE_HOMESCREEN',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_REQUEST_SUCCESS = 'FORGOT_PASSWORD_REQUEST_SUCCESS',
  FORGOT_PASSWORD_REQUEST_FAILURE = 'FORGOT_PASSWORD_REQUEST_FAILURE',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  CONFIRM_PASSWORD_SUCCESS = 'CONFIRM_PASSWORD_SUCCESS',
  CONFIRM_PASSWORD_FAILURE = 'CONFIRM_PASSWORD_FAILURE',
  SMS_CODE = 'SMS_CODE',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
}

export enum Signup_Steps {
  INITIAL_STAGE = 'INITIAL_STAGE',
  EMAIL_SIGN_UP = 'EMAIL_SIGN_UP',
  PASSWORD_SIGN_UP = 'PASSWORD_SIGN_UP',
  CODE_SIGN_UP = 'CODE_SIGN_UP',
  LOADING_SIGN_UP = 'LOADING_SIGN_UP',
  EMAIL_PASSWORD_LOGIN = 'EMAIL_PASSWORD_LOGIN',
  FORGOT_PASSWORD_LOGIN = 'FORGOT_PASSWORD_LOGIN',
  CODE_LOGIN = 'CODE_LOGIN',
  NEW_PASSWORD = 'NEW_PASSWORD',
  LOADING_LOGIN = 'LOADING_LOGIN',
}

// export enum GetStageActionsTypes {
//   SET_PASSWORD = 'SET_PASSWORD',
//   SIGNUP_FAILED_FORM = 'SIGNUP_FAILED_FORM',
//   SIGNUP_SUCCEEDED_FORM = 'SIGNUP_SUCCEEDED_FORM',
//   CONFIRM_SIGNUP = 'CONFIRM_SIGNUP',
//   SIGNIN = 'SIGNIN',
//   SIGNIN_SUCCESS = 'SIGNIN_SUCCESS',
//   SIGNIN_FAILURE = 'SIGNIN_FAILURE',
//   SET_EMAIL = 'SET_EMAIL',
//   SIGNUP_FAILED = 'SIGNUP_FAILED',
//   SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED',
// }

export interface SetEmail {
  type: GetServiceActionTypes.SET_EMAIL;
  username: string;
}

export interface SetPassword {
  type: GetServiceActionTypes.SET_PASSWORD;
  username: string;
  password: string;
}

export interface SigninConfirm {
  type: GetServiceActionTypes.SIGNIN_CONFIRM;
  password: string;
  username: string;
}

export interface SigninHeader {
  type: GetServiceActionTypes.SIGNIN_HEADER;
  username: string;
}

export interface InitialForm {
  type: GetServiceActionTypes.INITIAL_FORM;
}

export interface EmailSignup {
  type: GetServiceActionTypes.EMAIL_SIGNUP_FORM;
}

export interface PasswordSignup {
  type: GetServiceActionTypes.PASSWORD_SIGNUP_FORM;
}

export interface CodeSignup {
  type: GetServiceActionTypes.CODE_SIGNUP_FORM;
}

export interface LoadingSignup {
  type: GetServiceActionTypes.LOADING_SIGNUP_FORM;
}

export interface EmailLogin {
  type: GetServiceActionTypes.EMAIL_LOGIN_FORM;
}

export interface ForgotPassword {
  type: GetServiceActionTypes.FORGOT_PASSWORD_FORM;
  username: string;
}

export interface CodeLogin {
  type: GetServiceActionTypes.CODE_LOGIN_FORM;
}

export interface NewPassword {
  type: GetServiceActionTypes.NEW_PASSWORD_FORM;
}

export interface LoadingLogin {
  type: GetServiceActionTypes.LOADING_LOGIN_FORM;
}

export interface SucceededSignup {
  type: GetServiceActionTypes.SIGNUP_SUCCEEDED_FORM;
}

export interface FailedSignup {
  type: GetServiceActionTypes.SIGNUP_FAILED_FORM;
}

export interface ConfirmSignup {
  type: GetServiceActionTypes.CONFIRM_SIGNUP;
  code: string;
  username: string;
}

export interface SigninSuccess {
  type: GetServiceActionTypes.SIGNIN_SUCCESS;
}

export interface SigninFailure {
  type: GetServiceActionTypes.SIGNIN_FAILURE;
}

export interface SignIn {
  type: GetServiceActionTypes.SIGNIN;
  username: string;
  password: string;
}

export interface OpenBlock {
  type: GetServiceActionTypes.OPEN_BLOCK;
}

export interface CloseBlock {
  type: GetServiceActionTypes.CLOSE_BLOCK;
}

export interface OpenHomescreen {
  type: GetServiceActionTypes.OPEN_HOMESCREEN;
}

export interface CloseHomescreen {
  type: GetServiceActionTypes.CLOSE_HOMESCREEN;
}

export interface ForgotPasswordRequest {
  type: GetServiceActionTypes.FORGOT_PASSWORD_REQUEST;
  username: string;
}

export interface ForgotPasswordRequestSuccess {
  type: GetServiceActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS;
}

export interface ForgotPasswordRequestFailure {
  type: GetServiceActionTypes.FORGOT_PASSWORD_REQUEST_FAILURE;
  error: Error;
}

export interface ConfirmPassword {
  type: GetServiceActionTypes.CONFIRM_PASSWORD;
  code: string;
  password: string;
  username: string;
}

export interface ConfirmPasswordSuccess {
  type: GetServiceActionTypes.CONFIRM_PASSWORD_SUCCESS;
}

export interface ConfirmPasswordFailure {
  type: GetServiceActionTypes.CONFIRM_PASSWORD_FAILURE;
  error: Error;
}

export interface SmsCode {
  type: GetServiceActionTypes.SMS_CODE;
  code: string;
}

export interface SignOut {
  type: GetServiceActionTypes.SIGN_OUT;
}

export interface SignOutSuccess {
  type: GetServiceActionTypes.SIGN_OUT_SUCCESS;
}

export interface GetDevicesRequest {
  type: GetServiceActionTypes.GET_DEVICES_REQUEST;
  id: string;
  acquiring?: boolean;
}

export function getDevices(id: string, acquiring = false): GetDevicesRequest {
  return {
    type: GetServiceActionTypes.GET_DEVICES_REQUEST,
    id,
    acquiring,
  };
}

export function initial_Form(): InitialForm {
  return {
    type: GetServiceActionTypes.INITIAL_FORM,
  };
}

export function email_Signup(): EmailSignup {
  return {
    type: GetServiceActionTypes.EMAIL_SIGNUP_FORM,
  };
}

export function password_Signup(): PasswordSignup {
  return {
    type: GetServiceActionTypes.PASSWORD_SIGNUP_FORM,
  };
}

export function code_Signup(): CodeSignup {
  return {
    type: GetServiceActionTypes.CODE_SIGNUP_FORM,
  };
}

export function loading_Signup(): LoadingSignup {
  return {
    type: GetServiceActionTypes.LOADING_SIGNUP_FORM,
  };
}

export function setEmail(username: string): SetEmail {
  return {
    type: GetServiceActionTypes.SET_EMAIL,
    username,
  };
}

export function email_login(): EmailLogin {
  return {
    type: GetServiceActionTypes.EMAIL_LOGIN_FORM,
  };
}

export function forgot_password(username: string): ForgotPassword {
  return {
    type: GetServiceActionTypes.FORGOT_PASSWORD_FORM,
    username,
  };
}

export function code_login(): CodeLogin {
  return {
    type: GetServiceActionTypes.CODE_LOGIN_FORM,
  };
}

export function new_password(): NewPassword {
  return {
    type: GetServiceActionTypes.NEW_PASSWORD_FORM,
  };
}

export function loading_login(): LoadingLogin {
  return {
    type: GetServiceActionTypes.LOADING_LOGIN_FORM,
  };
}

export function succeededSignup(): SucceededSignup {
  return {
    type: GetServiceActionTypes.SIGNUP_SUCCEEDED_FORM,
  };
}

export function failedSignup(): FailedSignup {
  return {
    type: GetServiceActionTypes.SIGNUP_FAILED_FORM,
  };
}

export function setPassword(username: string, password: string): SetPassword {
  return {
    type: GetServiceActionTypes.SET_PASSWORD,
    username,
    password,
  };
}

export function confirmSignup(username: string, code: string): ConfirmSignup {
  return {
    type: GetServiceActionTypes.CONFIRM_SIGNUP,
    username,
    code,
  };
}

export function signinSuccess(): SigninSuccess {
  return {
    type: GetServiceActionTypes.SIGNIN_SUCCESS,
  };
}

export function signinFailure(): SigninFailure {
  return {
    type: GetServiceActionTypes.SIGNIN_FAILURE,
  };
}

export function signIn(username: string, password: string): SignIn {
  return {
    type: GetServiceActionTypes.SIGNIN,
    username,
    password,
  };
}

export function signinConfirm(username: string, password: string): SigninConfirm {
  return {
    type: GetServiceActionTypes.SIGNIN_CONFIRM,
    username,
    password,
  };
}

export function signinHeader(username: string): SigninHeader {
  return {
    type: GetServiceActionTypes.SIGNIN_HEADER,
    username,
  };
}

export function openBlock(): OpenBlock {
  return {
    type: GetServiceActionTypes.OPEN_BLOCK,
  };
}

export function closeBlock(): CloseBlock {
  return {
    type: GetServiceActionTypes.CLOSE_BLOCK,
  };
}

export function openHomescreen(): OpenHomescreen {
  return {
    type: GetServiceActionTypes.OPEN_HOMESCREEN,
  };
}

export function closeHomescreen(): CloseHomescreen {
  return {
    type: GetServiceActionTypes.CLOSE_HOMESCREEN,
  };
}

export function forgotPasswordRequest(username: string): ForgotPasswordRequest {
  return {
    type: GetServiceActionTypes.FORGOT_PASSWORD_REQUEST,
    username,
  };
}

export function forgotPasswordRequestSuccess(): ForgotPasswordRequestSuccess {
  return {
    type: GetServiceActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS,
  };
}

export function forgotPasswordRequestFailure(error: Error): ForgotPasswordRequestFailure {
  return {
    type: GetServiceActionTypes.FORGOT_PASSWORD_REQUEST_FAILURE,
    error,
  };
}

export function confirmPassword(username: string, code: string, password: string): ConfirmPassword {
  return {
    type: GetServiceActionTypes.CONFIRM_PASSWORD,
    code,
    password,
    username,
  };
}

export function confirmPasswordSuccess(): ConfirmPasswordSuccess {
  return {
    type: GetServiceActionTypes.CONFIRM_PASSWORD_SUCCESS,
  };
}

export function confirmPasswordFailure(error: Error): ConfirmPasswordFailure {
  return {
    type: GetServiceActionTypes.CONFIRM_PASSWORD_FAILURE,
    error,
  };
}

export function smsCode(code: string): SmsCode {
  return {
    type: GetServiceActionTypes.SMS_CODE,
    code,
  };
}

export function signOut(): SignOut {
  return {
    type: GetServiceActionTypes.SIGN_OUT,
  };
}

export function signOutSuccess(): SignOutSuccess {
  return {
    type: GetServiceActionTypes.SIGN_OUT_SUCCESS,
  };
}

export const validateEmail = () => ({
  type: GetServiceActionTypes.VALIDATE_EMAIL,
});

export type GetDevicesActions =
  | GetDevicesRequest
  | SetEmail
  | InitialForm
  | EmailSignup
  | PasswordSignup
  | CodeSignup
  | LoadingSignup
  | EmailLogin
  | ForgotPassword
  | CodeLogin
  | NewPassword
  | LoadingLogin
  | SetPassword
  | ConfirmSignup
  | SignIn
  | SigninConfirm
  | SigninHeader
  | SigninSuccess
  | SigninFailure
  | OpenBlock
  | CloseBlock
  | OpenHomescreen
  | CloseHomescreen
  | ForgotPasswordRequest
  | ForgotPasswordRequestSuccess
  | ForgotPasswordRequestFailure
  | ConfirmPassword
  | ConfirmPasswordSuccess
  | ConfirmPasswordFailure
  | SmsCode
  | SignOut
  | SignOutSuccess;
