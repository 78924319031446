/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { emailReducer } from '../components/weblogin/redux/reducer';

/* Local dependencies */
import getDevices from '../components/devices/getDevices/redux/reducer';
import {
  signUpConfirmCode,
  signUpEpic,
  signIn,
  forgotPassword,
  confirmPassword,
  signOut,
} from '../components/weblogin/redux/epics';

const rootEpic = combineEpics(signUpEpic, signUpConfirmCode, signIn, forgotPassword, confirmPassword, signOut);

const rootReducer = combineReducers({
  getDevices,
  emailReducer,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
