/* External dependencies */
import Cognitoclient from '@mancho.devs/cognito';
import { filter, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

/* Local dependencies */
import {
  ConfirmSignup,
  failedSignup,
  GetDevicesActions,
  GetServiceActionTypes,
  signinFailure,
  signinSuccess,
  SetPassword,
  succeededSignup,
  ForgotPasswordRequest,
  forgotPasswordRequestSuccess,
  forgotPasswordRequestFailure,
  confirmPasswordSuccess,
  confirmPasswordFailure,
  ConfirmPassword,
  signOutSuccess,
} from './action';

const CognitoClient = new Cognitoclient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function signUpEpic(action$): Observable<GetDevicesActions> {
  return action$.pipe(
    filter((action: GetDevicesActions) => action.type === GetServiceActionTypes.SET_PASSWORD),
    switchMap(({ username, password }: SetPassword) =>
      CognitoClient.signUp(username, password).then(succeededSignup).catch(failedSignup),
    ),
  );
}

export function signUpConfirmCode(action$): Observable<GetDevicesActions> {
  return action$.pipe(
    filter((action: GetDevicesActions) => action.type === GetServiceActionTypes.CONFIRM_SIGNUP),
    switchMap(({ username, code }: ConfirmSignup) =>
      CognitoClient.signUpConfirmCode(username, code).then(succeededSignup).catch(failedSignup),
    ),
  );
}

export function signIn(action$): Observable<GetDevicesActions> {
  return action$.pipe(
    filter((action: GetDevicesActions) => action.type === GetServiceActionTypes.SIGNIN),
    switchMap(({ username, password }: SetPassword) =>
      CognitoClient.signIn(username, password).then(signinSuccess).catch(signinFailure),
    ),
  );
}

export function forgotPassword(action$): Observable<GetDevicesActions> {
  return action$.pipe(
    filter((action: GetDevicesActions) => action.type === GetServiceActionTypes.FORGOT_PASSWORD_REQUEST),
    switchMap(({ username }: ForgotPasswordRequest) =>
      CognitoClient.forgotPassword(username).then(forgotPasswordRequestSuccess).catch(forgotPasswordRequestFailure),
    ),
  );
}

export function confirmPassword(action$): Observable<GetDevicesActions> {
  return action$.pipe(
    filter((action: GetDevicesActions) => action.type === GetServiceActionTypes.CONFIRM_PASSWORD),
    switchMap(({ username, code, password }: ConfirmPassword) =>
      CognitoClient.confirmPassword(username, code, password)
        .then(confirmPasswordSuccess)
        .catch(confirmPasswordFailure),
    ),
  );
}

export function signOut(action$): Observable<GetDevicesActions> {
  return action$.pipe(
    filter((action: GetDevicesActions) => action.type === GetServiceActionTypes.SIGN_OUT),
    switchMap(() => CognitoClient.signOut().then(signOutSuccess)),
  );
}
