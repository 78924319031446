import { GetDevicesActions, GetServiceActionTypes, Signup_Steps } from './action';
import update from 'immutability-helper';

export const initialState = {
  code: '',
  confirmPassword: '',
  email: '',
  error: '',
  isHomescreenOpen: true,
  isLoginOpen: false,
  isPasswordMatch: true,
  isValid: false,
  loading: false,
  newPassword: '',
  newPasswordError: '',
  newPasswordLoading: false,
  password: '',
  signupStep: Signup_Steps.INITIAL_STAGE,
  success: false,
  username: '',
};

export const emailRegex = /^\S+@\S+.\S+$/;
export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$#!%*?&]{8,}$/;

export function emailReducer(state = initialState, action: GetDevicesActions) {
  switch (action.type) {
    case GetServiceActionTypes.INITIAL_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.INITIAL_STAGE },
      });

    case GetServiceActionTypes.EMAIL_LOGIN_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.EMAIL_PASSWORD_LOGIN },
      });

    case GetServiceActionTypes.FORGOT_PASSWORD_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.FORGOT_PASSWORD_LOGIN },
      });

    case GetServiceActionTypes.CODE_LOGIN_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.CODE_LOGIN },
      });

    case GetServiceActionTypes.NEW_PASSWORD_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.NEW_PASSWORD },
      });

    case GetServiceActionTypes.LOADING_LOGIN_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.LOADING_LOGIN },
      });

    case GetServiceActionTypes.OPEN_BLOCK:
      return update(state, {
        isLoginOpen: { $set: true },
      });

    case GetServiceActionTypes.CLOSE_BLOCK:
      return update(state, {
        isLoginOpen: { $set: false },
      });

    case GetServiceActionTypes.OPEN_HOMESCREEN:
      return update(state, {
        isHomescreenOpen: { $set: true },
      });

    case GetServiceActionTypes.CLOSE_HOMESCREEN:
      return update(state, {
        isHomescreenOpen: { $set: false },
      });

    case GetServiceActionTypes.EMAIL_SIGNUP_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.EMAIL_SIGN_UP },
      });

    case GetServiceActionTypes.PASSWORD_SIGNUP_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.PASSWORD_SIGN_UP },
      });

    case GetServiceActionTypes.CODE_SIGNUP_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.CODE_SIGN_UP },
      });

    case GetServiceActionTypes.LOADING_SIGNUP_FORM:
      return update(state, {
        signupStep: { $set: Signup_Steps.LOADING_SIGN_UP },
      });

    case GetServiceActionTypes.SET_EMAIL:
      const { username } = action;

      if (action.username.match(emailRegex)) {
        return update(state, {
          signupStep: { $set: Signup_Steps.PASSWORD_SIGN_UP },
          error: { $set: '' },
          username: { $set: action.username },
        });
      }
      if (!username) {
        return update(state, {
          error: { $set: 'Введи почту' },
        });
      }
      return update(state, {
        error: { $set: 'Неверная почта' },
      });

    case GetServiceActionTypes.SET_PASSWORD:
      const { password } = action;

      if (passwordRegex.test(action.password)) {
        return update(state, {
          signupStep: { $set: Signup_Steps.CODE_SIGN_UP },
          password: { $set: action.password },
          isPasswordMatch: { $set: true },
        });
      }
      if (password !== state.confirmPassword) {
        return update(state, {
          error: { $set: 'Пароли не сходятся' },
          isPasswordMatch: { $set: false },
        });
      }
      return update(state, {
        error: { $set: 'Неверный пароль' },
        isPasswordMatch: { $set: true },
      });

    case GetServiceActionTypes.CONFIRM_SIGNUP:
      const { code } = action;

      if (action.code) {
        return update(state, {
          $unset: ['error'],
          loading: { $set: true },
          success: { $set: true },
        });
      }

    case GetServiceActionTypes.SIGNIN:
      if (action.username.match(emailRegex)) {
        return update(state, {
          username: { $set: action.username },
        });
      }
      return update(state, {
        error: { $set: 'Неверная почта или пароль' },
      });

    case GetServiceActionTypes.SIGNIN_SUCCESS:
      return update(state, {
        isLoginOpen: { $set: false },
        success: { $set: true },
      });

    case GetServiceActionTypes.SIGNIN_FAILURE:
      return update(state, {
        signupStep: { $set: Signup_Steps.EMAIL_PASSWORD_LOGIN },
        success: { $set: false },
      });

    case GetServiceActionTypes.SIGNIN_CONFIRM:
      if (action.username.match(emailRegex)) {
        return update(state, {
          username: { $set: action.username },
        });
      } else {
        return state;
      }
    case GetServiceActionTypes.SIGNIN_HEADER:
      return update(state, {
        username: { $set: action.username },
      });

    case GetServiceActionTypes.FORGOT_PASSWORD_REQUEST:
      return update(state, {
        username: { $set: action.username },
      });

    case GetServiceActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS:
      return update(state, {
        signupStep: { $set: Signup_Steps.CODE_LOGIN },
        newPassword: { $set: '' },
      });

    case GetServiceActionTypes.FORGOT_PASSWORD_REQUEST_FAILURE:
      return update(state, {
        signupStep: { $set: Signup_Steps.FORGOT_PASSWORD_LOGIN },
        newPasswordError: { $set: 'Неверная почта' },
      });

    case GetServiceActionTypes.SMS_CODE:
      if (action.code !== '') {
        return update(state, {
          code: { $set: action.code },
          signupStep: { $set: Signup_Steps.NEW_PASSWORD },
        });
      }
      return update(state, {
        signupStep: { $set: Signup_Steps.CODE_LOGIN },
      });

    case GetServiceActionTypes.CONFIRM_PASSWORD:
      return update(state, {
        password: { $set: action.password },
        newPasswordLoading: { $set: true },
      });

    case GetServiceActionTypes.CONFIRM_PASSWORD_SUCCESS:
      return update(state, {
        signupStep: { $set: Signup_Steps.EMAIL_PASSWORD_LOGIN },
      });

    case GetServiceActionTypes.CONFIRM_PASSWORD_FAILURE:
      return update(state, {
        signupStep: { $set: Signup_Steps.NEW_PASSWORD },
        error: { $set: 'Invalid Password' },
        newPasswordLoading: { $set: false },
      });

    case GetServiceActionTypes.SIGN_OUT:
      return update(state, {
        signupStep: { $set: Signup_Steps.INITIAL_STAGE },
        success: { $set: false },
        isHomescreenOpen: { $set: true },
      });
    default:
      return state;
  }
}
